import * as mdb from 'mdb-ui-kit'; // lib
import Wysiwyg from 'mdb-wysiwyg-editor'; // plugin

window.mdb = mdb
window.Wysiwyg = Wysiwyg // add plugin as a global object

// // import Swiper JS
// window.Swiper = require('swiper/swiper-bundle');
// // import Swiper styles

// const swiper = new Swiper('.swiper-container', {
//     autoplay: {
//         delay: 10000,
//     },
//     loop: true
// });

import $ from 'jquery';
window.jQuery = $;
window.$ = $;


// Global toast
const globalToast = mdb.Toast.getInstance(document.getElementById('globalToast'));

window.displayToast = function (title, body, style) {

    let styles = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'];
    style = styles.includes(style) ? style : 'primary';

    globalToast.update({
        color: style,
    })

    let toast = $("#globalToast");
    toast.find('.toast-header').text(title)
    toast.find('.toast-body').text(body)

    globalToast.show()
}


require("./custom");
require("./home")

const iconsAsyncAutocomplete = document.querySelector('#icon_async');

if (typeof(iconsAsyncAutocomplete) != 'undefined' && iconsAsyncAutocomplete != null){
    const asyncFilter = async (query) => {
    const url = `/icons?search=${encodeURI(query)}`;
    const response = await fetch(url);
    const data = await response.json();
    console.log(2489237)
    return data.results;
    };


    new mdb.Autocomplete(iconsAsyncAutocomplete, {
        filter: asyncFilter,
        displayValue: (value) => value.name,
        itemContent: (result) => {
            return `
            <div class="autocomplete-custom-item-content">
                <i class="uil ${result.value}"></i>
                <div class="autocomplete-custom-item">${result.name}</div>
            </div>
            `;
        },
    });

    iconsAsyncAutocomplete.addEventListener('itemSelect.mdb.autocomplete', (e) => {
        var value = e.value.value;
        var name = e.value.name;
        $(".selected-icon").html('<i class="uil '+value+'" data-icon="uil '+value+'"></i><span>'+name+'</span>')
    })

}

$("#save_icon").on("click", function(){
    var old_icon = $(".change_icon").data("icon");
    var icon = $(".selected-icon i").data("icon");
    if(icon == undefined){
        // no icon selected
        $(".icon-error").html("You have not selected an icon")
        return false;
    } else {
        $(".icon-clicktoedit").hide();
        var id = $(".change_icon").data("id");
        $.ajax({
            type : "put",
            dataType : "json",
            url : `/icon`,
            data: {
                "id" : id,
                "icon" : icon
            },
            success: function(response) {
                if(response.status == 'success'){
                    window.displayToast("Icon Saved", "The icon has been saved.", "success")
                    $("#change_icon .btn-close").trigger("click");
                    // $(".change_icon").removeClass(old_icon);
                    $(".change_icon").removeClass (function (index, className) {
                        return (className.match (/(^|\s)uil-\S+/g) || []).join(' ');
                    });
                    $(".change_icon").addClass(icon);
                    $(".icon-clicktoedit").show();
                } else {
                    window.displayToast("Icon Failed", "The icon failed to be updated.", "danger")
                }

            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                window.displayToast("Icon Failed", "The icon failed to be updated.", "danger")
            }
        });
    }
})


// Init all tooltips
window.initTooltips = function(){
    let tooltip_elements = $('*[data-mdb-toggle="tooltip"]')
    tooltip_elements.each((i, el) => {
        new mdb.Tooltip($(el), {
            trigger : 'hover'
        })
    })

}

$(window).on('load', function () {
    initTooltips()

    $(document).on('click', '*[data-mdb-toggle="tooltip"]', function(e) {
        $(this).tooltip('hide');
    })
})

$.fn.qfPopulateFormFields = function(fieldNamePrefix, fieldValues) {
    var $form = this,
        $targetFields = $form.find(`[name*="${fieldNamePrefix}["]`);

    // Reset form
    $targetFields.val('');
    $targetFields.find(`option`)
        .attr('selected', false)
        .prop('selected', false);
    $targetFields.filter('input[type=radio], input[type=checkbox]')
        .attr('checked', false)
        .prop('checked', false);

    // Populate form
    $.each(fieldValues, function(fieldName, fieldValue) {
        $targetFields.filter('input:not([type=radio]):not([type=checkbox]), textarea')
            .filter(`[name*="[${fieldName}]"]`)
            .val(fieldValue);

        if(Array.isArray(fieldValue)) {
            fieldValue.forEach(function(optionValue) {
                $targetFields.filter(`select[name*="[${fieldName}]"]`)
                    .find(`option[value="${optionValue}"]`)
                    .attr('selected', true)
                    .prop('selected', true);
            });
        } else {
            // .val() doesn't work with MDB Select fields. Have to brute-force it.
            $targetFields.filter(`select[name*="[${fieldName}]"]`)
                .find(`option[value="${fieldValue}"]`)
                .attr('selected', true)
                .prop('selected', true);
        }

        $targetFields.filter('input[type=radio], input[type=checkbox]')
            .filter(`[name*="[${fieldName}]"][value="${fieldValue}"]`)
            .attr('checked', true)
            .prop('checked', true);
    });
};

$.fn.qfAjaxForm = function() {
    var $form = this;
    $form.submit(function(event) {
        event.preventDefault();
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data:  new FormData(this),
            contentType: false,
            cache: false,
            processData:false,
            success: function(response) {
                window.displayToast("Success", $form.data('submit-success'), "success");
                location.reload();
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                window.displayToast("Error", $form.data('submit-error'), "danger");
            },
        });
    });
};

$.fn.qfHandleDataTableDelete = function() {
    var $deleteButton = this,
        $deleteRow = $deleteButton.closest('tr');

    $.ajax({
        type: $deleteButton.data('http-method'),
        url: $deleteButton.data('href'),
        data: {
            _token: $('meta[name="csrf-token"]').attr('content'),
        },
        success: function(response) {
            // @todo: Do this the correct way via DataTables (MDB gets in the way)
            $deleteRow.fadeOut(null, function() {
                $deleteRow.remove();
            });
            window.displayToast("Success", $deleteButton.data('ajax-message-success'), "success");
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
            window.displayToast("Error", $deleteButton.data('ajax-message-error'), "danger");
        },
    });
};

window.quickfire = {
    dataTable: {
        formatDateColumns(row, dateColumnKeys) {
            dateColumnKeys.forEach(function(dateColumn) {
                if(row[dateColumn]) {
                    let date = new Date(Date.parse(row[dateColumn]));
                    row[dateColumn] = date.toISOString().slice(0, -1); // Remove trailing "Z", as datetime form elements can't handle it
                    row[dateColumn+'_formatted'] = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                }
            });
        },
    },
};
